var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('form',{staticClass:"form flex-column"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body d-flex flex-column pt-5 px-0"},[_c('px-new-table',{staticClass:"align-middle",attrs:{"is-hover-support":false,"header":_vm.header,"items":_vm.inclusions,"paginate":false},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('textarea-autosize',{staticClass:"form-control form-control-solid fw-bold fs-16px text-body",attrs:{"rows":"1","min-height":42,"max-height":500},model:{value:(item.attributes.name),callback:function ($$v) {_vm.$set(item.attributes, "name", $$v)},expression:"item.attributes.name"}})]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('textarea-autosize',{staticClass:"form-control form-control-solid fw-normal min-w-300px fs-16px text-body",attrs:{"rows":"1","min-height":42,"max-height":500},model:{value:(item.attributes.description),callback:function ($$v) {_vm.$set(item.attributes, "description", $$v)},expression:"item.attributes.description"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('px-dropdown',{attrs:{"items":[
                {
                  title: 'Duplicate',
                  disabled: !item.attributes.item && !item.attributes.description,
                  action: function () { return _vm.duplicateInclusion(item.attributes); },
                },
                { title: 'Delete', action: function () { return _vm.deleteInclusion(item.id); } } ]}})]}}])})],1),_c('div',{staticClass:"card-footer align-items-center border-0 p-0 text-end"},[_c('px-btn',{attrs:{"size":"sm","color":"light-primary"},nativeOn:{"click":function($event){return _vm.addNewInclusion.apply(null, arguments)}}},[_c('i',{staticClass:"bi bi-plus-lg"}),_vm._v(" New Item ")])],1)])]),_c('div',{staticClass:"d-flex flex-stack mt-8 pt-8 border-top"},[_c('div',{staticClass:"me-4"},[_c('px-btn',{attrs:{"color":"secondary","in-process":_vm.prevInProcess,"disabled":_vm.prevInProcess},nativeOn:{"click":function($event){return _vm.goToPrev.apply(null, arguments)}}},[_vm._v(" Back ")])],1),_c('div',{staticClass:"ms-auto"},[_c('px-btn',{attrs:{"color":"success","extended-classes":"me-2","in-process":_vm.updateInProcess,"disabled":_vm.updateInProcess},nativeOn:{"click":function($event){return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" Save ")]),_c('px-btn',{attrs:{"in-process":_vm.nextInProcess,"disabled":_vm.nextInProcess},nativeOn:{"click":function($event){return _vm.goToNext.apply(null, arguments)}}},[_vm._v("Next ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }